import { ShotAttackTypes } from "../Constants";

const sharedActionGroups = [
  {
    actions: [
      { action: "sendEvent", value: "edit", type: "main" },
      { action: "copyEvent", value: "shot", type: "to" },
      { action: "nextState", value: "shot-player", type: "primary" },
    ],
    conditions: [],
  },
];

export const ShotAttackTypeSelect = {
  name: "shot-attack-type",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "shot.attackType",
  showOn: [{ state: "primary", value: "shot-attack-type", operator: "is" }],
  allowBack: {
    state: "primary",
    value: "shot-missed-outcome-type",
    event: "main",
  },
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: {},
  children: [
    {
      name: "goal-attack-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "eventOptions.failureReason",
          value: "SHOT_BLOCKED",
          operator: "not",
          event: "main",
        },
      ],
      options: {
        value: "singleOption",
        optionName: "attackType",
        children: ShotAttackTypes,
        layout: "type-button",
        prefix: "attackType",
      },
      actionGroups: sharedActionGroups,
    },
    {
      name: "goal-attack-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "eventOptions.failureReason",
          value: "SHOT_BLOCKED",
          operator: "is",
          event: "main",
        },
      ],
      options: {
        value: "singleOption",
        optionName: "attackType",
        children: ["DIRECT_FREE_THROW"],
        layout: "type-button",
        prefix: "attackType",
      },
      actionGroups: sharedActionGroups,
    },
    {
      name: "match-buttons",
      component: "ButtonPanel",
      layout: "match-buttons-stackable centered",
      mode: "visible",
      showOn: [true],
      children: [
        {
          name: "confirm-later-attack-type",
          component: "TypeButton",
          layout: "match-button",
          mode: "enable",
          text: "confirmLater",
          showOn: [true],
          actionGroups: [
            ...sharedActionGroups,
            {
              actions: [
                { action: "clearData", value: [{ option: "attackType" }] },
                { action: "saveData", type: "flagged", value: true },
                { action: "processInserts", value: "all", type: "inserts" },
              ],
              conditions: [],
            },
          ],
          startEvent: false,
          startPlay: false,
        },
        {
          name: "no-attack-type",
          component: "TypeButton",
          layout: "match-button",
          mode: "enable",
          text: "attackType.none",
          showOn: [true],
          actionGroups: sharedActionGroups,
          startEvent: false,
          startPlay: false,
        },
      ],
    },
  ],
};
